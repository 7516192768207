<template>
    <div class="modal-basic">
        <div class="modal-header w-100">
            <i class="material-icons" @click="onClickClose(1)">close</i>
        </div>
        <img
            @click="onClickEventPage"
            class="img-cover"
            :src="require('@/assets/images/wm-promotion/promotion-bg.png')"
            alt=""
        />
        <p @click="onClickClose(2)" class="text-center">다시 보지 않기</p>
    </div>
</template>

<script>
export default {
    name: 'ModalWmPromotion',
    methods: {
        onClickClose(val) {
            if (val === 2) localStorage.setItem('watch-wm-promotion', true)
            this.$emit('close')
        },
        onClickEventPage() {
            localStorage.setItem('watch-wm-promotion', true)
            this.$emit('close')

            setTimeout(() => {
                this.$stackRouter.push({ name: 'WMPromotionPage' })
            }, 200)
        },
    },
}
</script>

<style scoped lang="scss">
.modal-basic {
    width: 100% !important;
    height: 100% !important;
    position: relative;
    background: transparent;
    border: none;
    border-radius: 0;
    box-shadow: none;

    i {
        font-size: 25px;
        //float: right;
        color: white;
        margin-right: 24px;
    }
    .modal-header {
        display: flex;
        flex-direction: row-reverse;
    }
    .btn {
        position: absolute;
        bottom: 16px;
        left: 24px;
        height: 48px;
        width: calc(100% - 48px);
    }
    .text-center {
        color: white;
        text-decoration: underline;
    }
}
.img-cover {
    width: 100%;
    height: auto;
}
</style>
