import { render, staticRenderFns } from "./ModalWmPromotion.vue?vue&type=template&id=40ad7605&scoped=true"
import script from "./ModalWmPromotion.vue?vue&type=script&lang=js"
export * from "./ModalWmPromotion.vue?vue&type=script&lang=js"
import style0 from "./ModalWmPromotion.vue?vue&type=style&index=0&id=40ad7605&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40ad7605",
  null
  
)

export default component.exports